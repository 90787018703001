* {
  font-family: Verdana, Helvetica, sans-serif!important;
}
html, body, #root {
  height: 100vh;
}
.header-bar {
  background-color: #525BA2;
  position: fixed;
  align-items: center;
  height: 50px;
  top: 0;
  width: 100%;
  z-index: 15;
}
.login .header-bar, .dashboard .header-bar {
  box-shadow: 0 10px 15px -3px #555;
}
.header-bar img {
  max-height: 45px;
}
.sign-out-button {
  margin-bottom: 0;
}
.body-wrapper {
  background-image: url('../images/bg_logo.jpg');
  background-size: cover;
  height: 100%;
  align-items: center;
  background-position: top;
  padding-top: 50px;
}
.loading {
  height: 80vh;
}
.spacer-row {
  background-color: #eee;
  border-top: 1px solid #ddd;
}

input {
  min-height: 44px;
  border: 1px solid #525BA2 !important;
  color: #525BA2 !important;
}

.nav-bar {
  position: fixed;
  bottom: 0;
  background: #525BA2;
  box-shadow: 0px 5px 30px #555;
  height: 70px;
  width: 100%;
  z-index: 10;
}
.nav-bar .cell {
  border-right: 1px solid rgba(251,251,251,0.1);
}
.nav-bar a, .nav-bar button {
  color: white;
  font-size: 0.8rem;
  line-height: inherit;
}
.nav-bar svg {
  margin-right: auto;
  margin-left: auto;
}

