.button {
  background: #525BA2;
  border: 1px solid #525BA2;
  color: #fff;
  min-height: 44px;
}
.button:focus, .button:hover {
  background: #fff;
  border: 1px solid #525BA2;
  color: #525BA2;
}
.button.secondary {
  background: white;
  border: 1px solid #525BA2;
  color: #525BA2;
}
.button.secondary:focus, .button.secondary:hover {
  background: #525BA2;
  border: 1px solid #525BA2;
  color: white;
}